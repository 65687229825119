(function () {
  'use strict';

  angular
    .module('app')
    .controller("AppController", AppController);

  /* @ngInject */
  function AppController() { }

} ());
